import React from "react";

export default function Button1(props) {

    return (
        <>
            <div className="col-lg-12 mt-5 mb-3" style={{
                textAlign: props.button.style
            }}>
                <a href={props.button.BTNUrl} className="btn button-md radius-xl">{props.button.BTNLib}</a>
            </div>
        </>
    )
}