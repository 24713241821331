import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// Elements
import BackToTop from './elements/back-top';
import ScrollToTop from './elements/ScrollToTop';

// Home Pages
import Index from './pages/home';

// Error Pages
import Error from './pages/error/error-404';

//Mentions legales
import MentionsLegales from './pages/juridical/mentions-legales';

//Politique de confidentialité
import PolitiqueDeConfidentialite from './pages/juridical/politique-de-confidentialite';

// Page Copyright 
import Copyright from './pages/juridical/copyright';

export default function Markup() {
	return (
		<>
			<BrowserRouter basename={'/'}>
				<ScrollToTop />
				<Routes>
					{/* Home Pages */}
					<Route path='/' element={<Index />} />

					{/* Error Pages */}
					<Route path='*' element={<Error />} />

					{/* Mentions Legales Pages */}
					<Route path="/mentions-legales" element={<MentionsLegales />} />

					{/* Mentions Legales Pages */}
					<Route path="/politique-de-confidentialite" exact element={<PolitiqueDeConfidentialite />} />

					{/* Page Copyright */}
					<Route path='/copyright' element={<Copyright />} />
				</Routes>
			</BrowserRouter>
			<BackToTop />
		</>
	);
}