import React, { useState, useEffect } from 'react';
import axios from "axios";
import config from "../../../config.json";

// Layout
import Header from '../../layout/header/header';
import Footer from '../../layout/footer/footer';

// Element
import Banner from '../../elements/banner/banner';
import SEO from '../../elements/seo/seo';

// Picture
import AboutPic3 from '../../../images/video/pic4.webp';

// Modal
import Modal from '../../elements/modal/modal';

// Cards 
import Card3 from '../../elements/card/card-3';

// Text
import Text1 from '../../elements/text/text-1';
import Text2 from '../../elements/text/text-2';

// Button 
import Button1 from '../../elements/button/button-1';

import { NOM_SITE } from '../../../App';

export default function Index() {

	// modalVideo
	const [openModal, setOpenModal] = useState(false);

	// SETTER 
	const [meta, setMeta] = useState('')
	const [banner, setBanner] = useState('')
	const [sec1, setSec1] = useState('')

	// video
	const [videoId, setVideoId] = useState('')
	const [imagevideo, setImageVideo] = useState('https://rest2.yogan.pro/GETPhoto/WDA_427fbeff-a611-4974-a365-d295d45d51bc_Photo1')

	const [sec2, setSec2] = useState('')
	const [card1, setCard1] = useState('')
	const [card2, setCard2] = useState('')
	const [card3, setCard3] = useState('')
	const [sec3, setSec3] = useState('')
	const [buttunContact, setButtunContact] = useState('')

	useEffect(() => {
		axios.get(`${config.urlServer}api/vielocale`)
			.then(function (res) {
				setMeta(res.data)
				setBanner(res.data.Composents[0])
				setSec1(res.data.Composents[1])

				// video
				setVideoId(res.data.Composents[2].Corps)
				setImageVideo(res.data.Composents[2].Data[0].img)

				setSec2(res.data.Composents[3])

				// Cards
				setCard1(res.data.Composents[3].Data[0])
				setCard2(res.data.Composents[3].Data[1])
				setCard3(res.data.Composents[3].Data[2])

				setSec3(res.data.Composents[4])
				setButtunContact(res.data.Composents[4].Data[0])
			})
	});

	// CONST
	const metaData = {
		title: !meta.TitreMeta ? `${NOM_SITE} : Redonner du lien et de la proximité` : meta.TitreMeta,
		description: !meta.description ? "Un outil idéal pour la gestion et la mobilisation des événements locaux. Chaque utilisateur pourra renseigner sa ville et ces intérêts et être notifié pour ne rien manquer." : meta.description,
		canonical: !meta.URLCanonical ? "" : meta.URLCanonical
	}

	const bannerContent = {
		title: !banner.Titre ? `${NOM_SITE}` : banner.Titre,
		entitled: !banner.Corps ? `${NOM_SITE}` : banner.Corps
	}

	// Video ID
	const VideoId = {
		id: !videoId ? 'X1OEB7Qlcis' : videoId,
	}

	const picturevideo = !imagevideo ? AboutPic3 : imagevideo;

	const cards = [
		{
			icons: "flaticon-networking",
			title: !card1.Titre ? "Rassembler plus, autour de centres d’intérêts communs" : card1.Titre,
			p: !card1.Corps ? "Application gratuite de base pour les associations permettant une communication par centres d’intérêt locaux (concert, événement, kermesse, portes ouvertes, fêtes locales etc.)." : card1.Corps
		},
		{
			icons: "flaticon-target",
			title: !card2.Titre ? "Une communication locale qui ne s’oublie pas" : card2.Titre,
			p: !card2.Corps ? "Un ou plusieurs rappel(s) par évènement sont paramétrables par les utilisateurs. Alors que les affiches ne peuvent plus être librement exposées sur les ronds points et les tracts sont vite oubliés." : card2.Corps
		},
		{
			icons: "flaticon-computer",
			title: !card3.Titre ? "Dans le respect de la vie privée de chacun" : card3.Titre,
			p: !card3.Corps ? "Les utilisateurs ne donnent que leurs villes ou villages de résidence, centres d’intérêt et tranche d’âge. Les associations, ont ainsi un outil simple et efficace pour toucher tous les habitants selon les propositions." : card3.Corps
		},
	]

	const section3 = {
		copyright: !sec3.Corps ? `${NOM_SITE} est un logiciel édité et réalisé par` : sec3.Corps,
		copyrightLib: !sec3.BTNLib ? "yogan développement" : sec3.BTNLib,
		copyrightUrl: !sec3.BTNUrl ? "https://yogan.fr/" : sec3.BTNUrl,
	}

	const text1 = {
		title: !sec1.Titre ? "Redonnez du lien et de la proximité" : sec1.Titre,
		p: !sec1.Corps ? "Découvrez comment avoir plus d'impact localement." : sec1.Corps,
		alignText: "center",
		psize: "19px"
	}

	const text2 = {
		title: !sec2.Titre ? "Conçu avec des responsables associatifs" : sec2.Titre,
		p: !sec2.Corps ? "L'outil idéal pour la gestion et la mobilisation des événements locaux. Chaque utilisateur pourra renseigner sa ville et ces intérêts et être notifié pour ne rien manquer." : sec2.Corps,
		alignText: "center",
		psize: "19px"
	}

	const button = {
		BTNLib: !buttunContact.BTNLib ? "nous contacter" : buttunContact.BTNLib,
		BTNUrl: "https://yogan.fr/contact",
		style: "center"
	}

	return (
		<>
			{/* Menu */}
			<Header />

			{/* Dynamic Meta Tags */}
			<SEO
				title={metaData.title}
				description={metaData.description}
				canonical={metaData.canonical}
			/>

			{/* if openModal == true then we runing Modal component  */}
			{openModal && <Modal setOpenModal={setOpenModal} VideoId={VideoId.id} />}

			<Banner title={bannerContent.title} entitled={bannerContent.entitled} navTitle="Yogan" navLink="https://yogan.fr/" hide="true" />

			{/* <!-- Redonnez du lien et de la proximité Start --> */}
			<div className="section-area section-sp2" id="content-area">
				<div className="container" id="presentation">
					<Text1 text={text1} />
					<div className="row section-sp4 content-center">
						<div className="col-lg-8 wow fadeInUp m-md-b30" data-wow-delay="0.4s">
							<div className="video-media-bx">
								<img src={picturevideo} alt={`Yogan Développement - ${NOM_SITE} application`} />
								<a className="popup-youtube video-zoom" onClick={() => setOpenModal(true)}><i className="fa fa-play"></i></a>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* <!-- Redonnez du lien et de la proximité End --> */}


			{/* <!-- Conçu avec des responsables associatifs Start --> */}
			<div className="section-area section-sp2 bg-gray" id="conception">
				<div className="container">

					<div className="heading-bx text-center mb-5">
						<Text1 text={text2} />
					</div>

					<Card3 cards={cards} />

				</div>
			</div>
			{/* <!-- Conçu avec des responsables associatifs End --> */}


			{/* <!-- Édité par yogan Start --> */}
			<div className="section-area section-sp2" id="contact">
				<div className="container">

					<Text2 text={section3} />

					<Button1 button={button} />

				</div>
			</div>
			{/* <!-- Édité par yogan End --> */}


			<Footer />
		</>
	);
}
