import React, { useState, useEffect } from "react";

// Get Api
import axios from "axios";
import config from "../../../config.json";

// Layout
import Footer from "../../layout/footer/footer";

// Element
import SEO from '../../elements/seo/seo';
import Banner from '../../elements/banner/banner';

export default function Copyright() {

    // SETTER
    const [meta, setMeta] = useState('')
    const [banner, setBanner] = useState('')
    const [data, setData] = useState('')

    useEffect(() => {
        axios.get(`${config.urlServer}api/copyright`)
            .then(function (res) {
                // get back data from API 
                setMeta(res.data)
                setBanner(res.data.Composents[0])
                setData(res.data.Composents[1])
            })
    });

    // CONST 
    const metaData = {
        // title: !meta.TitreMeta ? "Régles relatives au droit d'auteur" : meta.TitreMeta,
        description: !meta.Description ? "Les textes et éléments constitutifs du site sont protégés contre la reproduction et la contrefaçon par les lois en vigueur relatives à la protection intellectuelle." : meta.Description
    }

    const bannerContent = {
        title: !banner.Titre ? "Copyright" : banner.Titre,
        entitled: !banner.Corps ? "Copyright" : banner.Corps
    }

    const content = {
        title: !data.Titre ? "Règles Relatives Au Droit D'auteur ©" : data.Titre,
        corps: data.Corps
    }

    return (
        <>
            {/* Dynamic Meta Tags */}
            <SEO
                title={"Règles relatives au droit d'auteur"}
                description={metaData.description}
                canonical="copyright"
            />

            <Banner title={bannerContent.title} entitled={bannerContent.entitled} navTitle="Accueil" navLink="/" />


            {/* <!-- Règles relatives au droit d'auteur Start --> */}
            <div className="page-content bg-white">
                <div className="content-block" id="content-area">
                    <div className="section-area section-sp1">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 col-md-6 mb-5">
                                    <h2 className="text-capitalize text-center">{content.title}</h2>
                                    <div>
                                        <blockquote>
                                            <span>
                                                Nous tenons à rappeler que les textes et créations présentes sur le présent site sont l’unique propriété de <a href="https://yogan.fr" target="_blank" rel="noreferrer">Yogan Développement</a> et que toute reproduction,
                                                même partielle, est strictement interdite sauf accord préalable et écrit.
                                            </span>
                                        </blockquote>
                                        <p>
                                            En plus d'enfreindre les droits de propriété intellectuelle,
                                            les moteurs de recherche comme Google pénalisent fortement le contenu dupliqué qui consiste en une copie du même texte plusieurs fois sur différents sites.
                                        </p>
                                        <p>
                                            Cette mauvaise pratique est néfaste pour le référencement des sites impliqués est peut-être lourde de conséquences pour les deux parties concernées.
                                        </p>
                                        <p>
                                            Pour en savoir plus sur les règles relatives au Copyright nous vous conseillons de vous informer et
                                            de prendre connaissance de vos responsabilités sur les <a href="https://www.inpi.fr/comprendre-la-propriete-intellectuelle/les-autres-modes-de-protection/le-droit-dauteur" target="_blank" rel="noreferrer">sites officiels en ligne</a>.
                                            Toute l'équipe de <a href="https://yogan.fr" className="link-hover-primary" target="_blank" rel="noreferrer">Yogan Développement</a> vous remercie par avance pour votre considération.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Règles relatives au droit d'auteur end --> */}

            <Footer />
        </>
    )
}