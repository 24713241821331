import React from 'react';
import Markup from './markup/markup';

// Plugins Stylesheet
import 'bootstrap/dist/css/bootstrap.min.css';

// StyleSheet
import './css/typography.css';
import './css/shortcodes/shortcodes.css';
import './css/style.css';
import './css/color/color-1.css';

// Fonts
import './vendors/fontawesome/css/font-awesome.min.css';
import './vendors/flaticon/flaticon.css';
//import './vendors/line-awesome/css/line-awesome.min.css';
import './vendors/themify/themify-icons.css';

// Cookie
import Cookie from './markup/elements/cookie/cookie';

//Hubspot
import Hubspot from './markup/elements/hubspot/hubspot';

// CONST GLOBALE
export const URL_SITE = 'www.vielocale.fr';
export const NOM_SITE = 'VieLocale';

export default function App() {
	return (
		<div className="page-wraper">

			<Cookie />

			<Markup />

			<Hubspot />

		</div>
	);
}

