import React from 'react';
import { Link } from "react-router-dom";

export default function Footer() {
	return (
		<>
			<footer>
				<div className="footer-top">
					<div className="container">
						<div className="row">
							<div className="col-12 col-lg-12 col-md-12 col-sm-12 order-2 text-center">
								<div className="row">
									<div className="col-12 col-lg-12 col-md-12 col-sm-12">
										<div className="footer-innner-btm">
											<div className="copyright-bx">
												<p><Link to="/mentions-legales" className="link-footer-white">Mentions légales</Link></p>
											</div>
											<div className="copyright-bx">
												<p><Link to="/politique-de-confidentialite" className="link-footer-white">Politique de confidentialité</Link></p>
											</div>
											<div className="copyright-bx">
												<p><Link to="/copyright" className="link-footer-white">©</Link> {new Date().getFullYear()} <Link to="/" className="link-footer-white">VieLocale</Link></p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</footer>
		</>
	);
}